<template>
  <v-container fluid>
    <v-layout column>
      <v-card>
        <v-card-title>
          Edit Ticket
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="1">
              <v-text-field
                v-model="ticket.id"
                label="Id"
                outlined
                dense
                readonly
            />
            </v-col>
            <v-col
              cols="5">
              <v-text-field
                v-model="ticket.customerName"
                label="Name"
                outlined
                readonly
                dense
              />
            </v-col>
            <v-col
              cols="6">
              <v-text-field
                v-model="ticket.customerAddress"
                label="Address"
                outlined
                dense
                readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4">
              <v-select
                v-model="ticket.category"
                :items="categories"
                label="Category"
                outlined
                dense
                :disabled="!user.isSuperAdmin"
                @change="onTicketDataChange"
            />
            </v-col>
            <v-col
              cols="4">
              <v-select
                v-model="ticket.status"
                :items="statuses"
                label="Status"
                outlined
                dense
                :disabled="user.isManager"
                @change="onTicketDataChange"
              />
            </v-col>
            <v-col
              cols="4">
              <v-select
                v-model="ticket.managerId"
                :items="managers"
                label="Manager"
                outlined
                dense
                :disabled="!user.isSuperAdmin"
                @change="onTicketDataChange"
              />
            </v-col>
          </v-row>
          <v-textarea
            v-model="ticket.description"
            label="Description"
            auto-grow
            outlined
            readonly
          />
          <div
            v-if="!user.isManager"
            class="mb-5 d-flex justify-space-between">
            <div class="d-flex justify-space-between">
              <v-btn
                color="success"
                @click="initiateOutgoingCall"
                :disabled="user.isManager || isResolved">
                Initiate Call
              </v-btn>
            <v-alert
              v-if="isResolved"
              color="blue"
              dark
              dense
              class="ml-3"
              border="left"
              type="warning"
            >
              You cannot initiate a call from a resolved ticket.
            </v-alert>
          </div>
          <SendMessageModal
            :ticketId="ticket.id"
            :phone-number="ticket.customerPhoneNumber"
            @reload-ticket="loadTicket"/>
          </div>
          <v-row>
            <v-col cols="6">
              <CallLog
                :calls="ticket.calls" />
            </v-col>
            <v-col cols="6">
              <MessageLog
                :messages="ticket.messages"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <Notes
                :ticket-id="ticketId"
                :notes="ticket.notes"
                @reload-ticket="loadTicket" />
            </v-col>
            <v-col cols="6">
              <Attachments
                :ticket-id="ticketId"
                :attachments="ticket.attachments"
                @reload-ticket="loadTicket" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import CallLog from '@/components/CallLog.vue';
import MessageLog from '@/components/MessageLog.vue';
import Notes from '@/components/Notes.vue';
import Attachments from '@/components/Attachments.vue';
import userMixin from '@/components/mixins/userMixin';

import { success, error } from '@/shared/helpers/notificationHelper';
import { getTicket, updateTicket, getManagers } from '../shared/apis/ticketApi';
import { TICKET_STATUSES, TICKET_CATEGORIES, TICKET_STATUS_LOOKUP } from '../shared/constants/ticketConstants';

export default {
  name: 'edit-ticket',
  mixins: [userMixin],
  props: {
    customerId: Number,
    ticketId: Number,
  },
  pageTitle: 'Ticket',
  components: {
    CallLog,
    MessageLog,
    Notes,
    Attachments,
    SendMessageModal: () => import('@/components/SendMessageModal.vue'),
  },
  data() {
    return {
      loading: false,
      ticket: {
        description: '',
        notes: [],
        attachments: [],
        calls: [],
        messages: [],
        status: null,
        category: null,
        managerId: null,
      },
      categories: [
        {
          text: '',
          value: '',
        },
      ],
      statuses: [
        {
          text: '',
          value: '',
        },
      ],
      managers: [],
    };
  },
  computed: {
    isResolved() {
      return this.ticket.status === TICKET_STATUS_LOOKUP.RESOLVED_BY_FIELD_MANAGER
      || this.ticket.status === TICKET_STATUS_LOOKUP.RESOLVED_BY_CUSTOMER_CARE;
    },
  },
  async created() {
    this.loadTicket();
    this.managers = await getManagers();
    this.categories = TICKET_CATEGORIES;
    this.statuses = TICKET_STATUSES;
  },
  methods: {
    async onTicketDataChange() {
      await this.submitTicket();
    },
    async submitTicket() {
      const formData = new FormData();
      // formData.append('description', this.ticket.description);
      formData.append('status', this.ticket.status);
      if (this.ticket.managerId) formData.append('manager_id', this.ticket.managerId);
      formData.append('category', this.ticket.category);
      // formData.append('customer_id', this.customerId);

      try {
        await updateTicket(this.ticketId, formData);
        success('Ticket updated successfully.');
      } catch (e) {
        error(e.message);
      }
    },
    async loadTicket() {
      this.ticket = await getTicket(this.ticketId);
    },
    initiateOutgoingCall() {
      const params = { To: this.ticket.customerPhoneNumber };
      try {
        this.$store.state.twilio.device.connect(params);
      } catch (e) {
        error("Click the 'Not Ready' button to ready up for calls.");
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
