<template>
  <v-data-table
    :headers="headers"
    :items="tickets"
    :search="search"
    sort-by="createdAt"
    :sort-desc="true"
  >
    <template v-slot:item.category="{ item }">
      <v-chip>
        {{ categoryText(item.category) }}
      </v-chip>
    </template>
    <template v-slot:item.createdAt="{ item }">
      {{ getFormattedDate(item.createdAt) }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip>
        {{ statusText(item.status) }}
      </v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="viewTicket(item)"
      >
        mdi-eye
      </v-icon>
      <!-- <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon> -->
    </template>
  </v-data-table>
</template>

<script>
import { TICKET_CATEGORIES, TICKET_STATUSES } from '@/shared/constants/ticketConstants';
import { formatShortFullDate } from '@/shared/helpers/dayJsHelper';
import { getTicketsByCustomerId } from '../shared/apis/ticketApi';

export default {
  name: 'tickets',
  props: {
    customerId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Category', value: 'category' },
        { text: 'Description', value: 'description' },
        { text: 'Created Date', value: 'createdAt' },
        { text: 'Status', value: 'status' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      tickets: [],
    };
  },
  computed: {

  },
  async created() {
    this.tickets = await getTicketsByCustomerId(this.customerId);
  },
  methods: {
    viewTicket(item) {
      this.$router.push({
        name: 'edit_ticket',
        params: {
          ticketId: item.id,
          customerId: item.customerId,
        },
      });
    },
    categoryText(categoryId) {
      return TICKET_CATEGORIES.find((x) => x.value === categoryId).text;
    },
    statusText(statusId) {
      return TICKET_STATUSES.find((x) => x.value === statusId).text;
    },
    getFormattedDate(date) {
      return formatShortFullDate(date);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
