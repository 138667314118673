<template>
  <v-container fluid>
    <v-layout column>
      <v-card>
        <v-card-title>
          Create Ticket
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="4">
              <v-select
                v-model="ticket.category"
                :items="categories"
                label="Category"
                outlined
                dense
            />
            </v-col>
            <v-col
              cols="4">
              <v-select
                v-model="ticket.status"
                :items="statuses"
                label="Status"
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="4">
              <v-select
                v-model="ticket.managerId"
                :items="managers"
                label="Manager"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-textarea
            v-model="ticket.description"
            label="Description"
            auto-grow
            outlined
          />
          <v-text-field
            v-model="ticket.note"
            label="Note"
            outlined
            dense
          />
          <v-file-input
            v-model="ticket.attachments"
            label="Attachments"
            outlined
            dense
            multiple
            chips
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            :loading="loading"
            @click.native="submitTicket"
          >
            Create Ticket
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import { createTicket, getManagers } from '../shared/apis/ticketApi';
import { TICKET_STATUSES, TICKET_CATEGORIES } from '../shared/constants/ticketConstants';

export default {
  props: {
    customerId: Number,
    ticketId: Number,
  },
  pageTitle: 'Ticket',
  components: {
  },
  data() {
    return {
      loading: false,
      ticket: {
        description: '',
        note: '',
        attachments: [],
        status: null,
        category: null,
        managerId: null,
      },
      categories: [
        {
          text: '',
          value: '',
        },
      ],
      statuses: [
        {
          text: '',
          value: '',
        },
      ],
      managers: [],
    };
  },
  computed: {
    userId() {
      return this.$store.state.user.user.id;
    },
  },
  async created() {
    this.categories = TICKET_CATEGORIES;
    this.statuses = TICKET_STATUSES;
    this.managers = await getManagers();
  },
  methods: {
    async submitTicket() {
      const formData = new FormData();
      formData.append('description', this.ticket.description);
      formData.append('status', this.ticket.status);
      formData.append('category', this.ticket.category);
      formData.append('note', this.ticket.note);
      formData.append('customer_id', this.customerId);
      formData.append('created_by_id', this.userId);
      if (this.ticket.managerId > 0) {
        formData.append('manager_id', this.ticket.managerId);
      }
      for (let i = 0; i < this.ticket.attachments.length; i += 1) {
        formData.append('attachments[]', this.ticket.attachments[i]);
      }

      const result = await createTicket(formData);
      const content = 'Ticket Created.';
      const color = 'success';
      this.$store.commit('showMessage', { content, color });
      this.$router.push({
        name: 'edit_ticket',
        params: {
          ticketId: result.id,
          customerId: this.customerId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
