<template>
  <v-card>
    <v-card-title>
      Message Log
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="messages"
      :search="search"
    >
      <template v-slot:item.messageType="{ item }">
        <v-chip>
          {{ item.messageType }}
        </v-chip>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ getFormattedDate(item.createdAt) }}
      </template>
      <template v-slot:item.actions="{ }">
        <v-icon
          small
          class="mr-2"
          @click="$router.push('/customer')"
        >
          mdi-eye
        </v-icon>
        <!-- <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon> -->
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { formatShortFullDate } from '@/shared/helpers/dayJsHelper';

export default {
  name: 'message-log',
  props: {
    messages: Array,
    ticket: Object,
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Date',
          align: 'start',
          sortable: false,
          value: 'createdAt',
        },
        { text: 'Content', value: 'body' },
        { text: 'Message Direction', value: 'messageType' },
      ],
    };
  },
  created() {},
  methods: {
    getFormattedDate(date) {
      return formatShortFullDate(date);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
