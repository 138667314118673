<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        Add Note
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline primary lighten-2 white--text">
        Add Note
      </v-card-title>

      <v-card-text
        class="mt-5"
      >
        <v-textarea
          v-model="note"
          label="Note"
          outlined
          dense
        />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          @click="addNote()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNote } from '../shared/apis/ticketApi';

export default {
  name: 'AddNoteModal',
  props: {
    ticketId: Number,
  },
  data() {
    return {
      dialog: false,
      note: '',
    };
  },
  computed: {
    userId() {
      return this.$store.state.user.user.id;
    },
  },
  methods: {
    async addNote() {
      try {
        const result = await createNote(
          {
            ticketId: this.ticketId,
            content: this.note,
            createdById: this.userId,
          },
        );
        if (result) {
          this.dialog = false;
          this.$emit('reload-ticket');
        } else console.log('note did not save successfully.');
      } catch {
        console.log('error occurred while adding note.');
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
