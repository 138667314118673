<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="text-center">
    <v-dialog
      :value="true"
      width="500"
    >
      <template v-slot:activator="{on, attrs}">
        <slot
          name="activator"
          v-bind="{on, attrs}"
        />
      </template>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ title }}
        </v-card-title>

        <v-card-text
          class="mt-3"
        >
          <div v-html="formattedContent" />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="swapButtonColors ? 'primary' : 'error'"
            @click="$close(false)"
          >
            {{ cancel }}
          </v-btn>
          <v-btn
            :color="swapButtonColors ? 'error' : 'primary'"
            @click="$close(true)"
          >
            {{ confirm }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    confirm: {
      type: String,
      default: 'Yes',
    },
    cancel: {
      type: String,
      default: 'No',
    },
    swapButtonColors: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedContent() {
      return this.content.replace(/\n/g, '<br />');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
