<template>
  <v-row>
    <v-col
      cols="6"
      offset="3">
      <v-card
        v-if="user">
        <v-card-title>
          User Profile
        </v-card-title>
        <v-card-text>
          <v-text-field
            :value="user.email"
            outlined
            dense
            label="Email"
            readonly>
          </v-text-field>
          <v-select
            v-model="user.companyId"
            :items="companies"
            item-text="name"
            item-value="id"
            outlined
            dense
            label="Company">
          </v-select>
          <v-select
            v-model="user.role"
            :items="roles"
            outlined
            dense
            label="Role">
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="submitUser">
            Save User
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
import { getUser, updateUser } from '@/shared/apis/userApi';
import { getCompanies } from '@/shared/apis/companyApi';
import { ROLES } from '@/shared/constants/roleConstants';

export default {
  name: 'profile',
  data() {
    return {
      user: [],
      companies: [],
      roles: [],
    };
  },
  computed: {
    userId() {
      return parseInt(this.$route.params.id, 10);
    },
  },
  async created() {
    this.user = await getUser(this.userId);
    this.companies = await getCompanies();
    this.roles = ROLES;
  },
  methods: {
    async submitUser() {
      const data = {
        email: this.user.email,
        companyId: this.user.companyId,
        role: this.user.role,
      };
      await updateUser(this.userId, data);
      this.$store.commit('showMessage', { content: 'User Updated.', color: 'success' });
      this.user = await getUser(this.userId);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
