<template>
  <v-card
    class="mt-3">
    <v-card-title class="d-flex justify-space-between">
      Attachments
      <add-attachment-modal
        :ticketId="ticketId"
        @reload-ticket="reloadTicket" />
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                File Name
              </th>
              <th class="text-left">
                File Type
              </th>
              <th class="text-left">
                Uploaded At
              </th>
              <th class="text-left">
                Download
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in attachments"
              :key="item.content"
            >
              <td>{{ item.fileName }}</td>
              <td>{{ item.contentType }}</td>
              <td>{{ getFormattedDate(item.createdAt) }}</td>
              <td>
                <v-icon
                  @click="downloadAttachment(item.url)">
                  mdi-download
                </v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import AddAttachmentModal from '@/components/AddAttachmentModal.vue';
import { formatShortFullDate } from '@/shared/helpers/dayJsHelper';

export default {
  name: 'attachments',
  props: {
    ticketId: Number,
    attachments: Array,
  },
  components: {
    AddAttachmentModal,
  },
  methods: {
    downloadAttachment(url) {
      window.open(process.env.VUE_APP_API_BASE_URL + url, '_blank');
    },
    reloadTicket() {
      this.$emit('reload-ticket');
    },
    getFormattedDate(date) {
      return formatShortFullDate(date);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
