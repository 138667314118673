<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <v-container fluid>
      <v-layout column>
        <v-card>
          <v-card-text>
            <v-text-field
              v-model="customer.name"
              label="Name"
            />
            <v-text-field
              v-model="customer.address"
              label="Address"
            />
            <v-text-field
              v-model="customer.phoneNumber"
              label="Phone Number"
              :rules="phoneNumberRules"
              required
            />
            <v-text-field
              v-model="customer.email"
              label="Email Address"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              :loading="loading"
              @click.native="submitCustomer()"
            >
              Save Customer
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="user.isSuperAdmin"
              color="error"
              @click="deleteCustomer"
            >
              Delete Customer
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-if="customerId > 0"
          class="mt-5"
        >
          <v-card-actions>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="customer.inProgressTicket"
              @click.native="$router.push(
                {
                  name: 'new_ticket',
                  params: {
                    customerId: customerId
                  }
                })"
            >
              New Ticket
            </v-btn>
            <v-alert
              v-if="customer.inProgressTicket"
              color="blue"
              dark
              dense
              class="mt-4 ml-4"
              border="left"
              type="warning"
            >
              You must resolve old tickets before creating a new one.
            </v-alert>
          </v-card-actions>
          <v-card-text>
            <Tickets
              :customer-id="customerId"
            />
          </v-card-text>
        </v-card>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import userMixin from '@/components/mixins/userMixin';
import Tickets from '@/components/Tickets.vue';
import ConfirmDialog from '@/components/elements/ConfirmDialog.vue';
import { create } from 'vue-modal-dialogs';
import {
  getCustomerById, createCustomer, updateCustomer, deleteCustomer,
} from '@/shared/apis/customerApi';

const confirmDialog = create(ConfirmDialog);

export default {
  components: {
    Tickets,
  },
  mixins: [userMixin],
  pageTitle: 'My Profile',
  data() {
    return {
      valid: false,
      loading: false,
      customer: {
        name: '',
        address: '',
        phoneNumber: '',
      },
      phoneNumberRules: [
        (v) => (v.charAt(0) === '+' && v.length === 12) || 'Must be 11 digits with a leading + sign.',
      ],
    };
  },
  computed: {
    customerId() {
      return parseInt(this.$route.params.id, 10);
    },
  },
  async created() {
    if (this.$route.params.id > 0) {
      this.customer = await getCustomerById(this.$route.params.id);
    }
  },
  methods: {
    async submitCustomer() {
      this.$refs.form.validate();
      if (this.valid) {
        const data = {
          name: this.customer.name,
          address: this.customer.address,
          phoneNumber: this.customer.phoneNumber,
          email: this.customer.email,
        };
        let result;
        if (this.customerId === 0) {
          result = await createCustomer(data);
          this.$store.commit('showMessage', { content: 'Customer Created.', color: 'success' });
          this.$router.push(`/customer/${result.id}`);
        } else {
          result = await updateCustomer(this.customerId, data);
          this.$store.commit('showMessage', { content: 'Customer Updated.', color: 'success' });
          this.customer = await getCustomerById(this.customerId);
        }
      }
    },
    async deleteCustomer() {
      const result = await confirmDialog({
        title: 'Are you sure?',
        content: 'Are you sure you want to delete the customer? \n\n This will also delete <b>everything</b> related to the customer, such as calls, tickets, signups, and attachments. \n \n <h3 style="color: red;">This action cannot be undone.</h3>',
        confirm: 'Delete Customer',
        cancel: 'Cancel',
        swapButtonColors: true,
      });

      if (!result) return;

      await deleteCustomer(this.customerId);
      this.$store.commit('showMessage', { content: 'Customer Deleted.', color: 'success' });
      this.$router.replace('/directory');
    },
  },
};
</script>
