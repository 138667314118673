<template>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
          :disabled="user.isManager"
        >
          Add Attachments
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline primary lighten-2 white--text">
          Add Attachments
        </v-card-title>

        <v-card-text
         class="mt-5">
          <v-file-input
            v-model="attachments"
            label="Attachment"
            outlined
            dense
            multiple
            chips />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="addAttachment()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import userMixin from '@/components/mixins/userMixin';
import { createAttachment } from '../shared/apis/ticketApi';

export default {
  name: 'add-attachment-modal',
  mixins: [userMixin],
  props: {
    ticketId: Number,
  },
  data() {
    return {
      dialog: false,
      attachments: [],
    };
  },
  methods: {
    async addAttachment() {
      const formData = new FormData();
      formData.append('ticket_id', this.ticketId);
      for (let i = 0; i < this.attachments.length; i += 1) {
        formData.append('attachments[]', this.attachments[i]);
      }

      try {
        const result = await createAttachment(formData);
        if (result) {
          this.dialog = false;
          this.$emit('reload-ticket');
        }
        // TODO: add error handling
        console.log(result);
      } catch (e) {
        console.log(e.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
