<template>
      <v-card
        class="mt-3">
        <v-card-title class="d-flex justify-space-between">
          Notes
          <add-note-modal
            :ticketId="ticketId"
            @reload-ticket="reloadTicket"/>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Date
                  </th>
                  <th class="text-left">
                    Content
                  </th>
                  <th class="text-left">
                    Created By
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in notes"
                  :key="item.content"
                >
                  <td>{{ getFormattedDate(item.createdAt) }}</td>
                  <td>{{ item.content }}</td>
                  <td>{{ item.createdBy }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
</template>

<script>
import AddNoteModal from '@/components/AddNoteModal.vue';
import { formatShortFullDate } from '@/shared/helpers/dayJsHelper';

export default {
  props: {
    ticketId: Number,
    notes: Array,
  },
  components: {
    AddNoteModal,
  },
  methods: {
    reloadTicket() {
      this.$emit('reload-ticket');
    },
    getFormattedDate(date) {
      return formatShortFullDate(date);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
