<template>
  <v-container fluid>
    <v-layout column>
      <v-card>
        <v-card-title>
          {{ isViewOnly ? 'View' : 'Edit' }} Signup
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="signup.ownerName"
                label="Home / Building Owner Name"
                outlined
                dense
                :disabled="!user.isSuperAdmin"
              />
              <v-text-field
                v-model="signup.ownerEmail"
                label="Email"
                outlined
                dense
                :disabled="!user.isSuperAdmin"
              />
              <v-text-field
                v-model="signup.ownerPhoneNumber"
                label="Phone Number"
                outlined
                dense
                :disabled="!user.isSuperAdmin"
              />
              <v-text-field
                v-model="signup.ownerAlternatePhoneNumber"
                label="Alternate Phone Number"
                outlined
                dense
                :disabled="!user.isSuperAdmin"
              />
              <div class="d-flex justify-space-between">
                <v-checkbox
                  v-model="isOwnerOccupied"
                  label="Owner Occupied"
                  class="pt-0 mt-0"
                  outlined
                  dense
                />
                <v-checkbox
                  v-model="signup.isReferredToSales"
                  label="Refer to sales"
                  class="pt-0 mt-0"
                  outlined
                  dense
                  :disabled="!user.isSuperAdmin && isViewOnly"
                />
              </div>
            </v-col>
            <v-divider vertical />
            <v-col>
              <v-text-field
                v-model="signup.occupantName"
                label="Occupant's Name"
                outlined
                dense
                :disabled="!user.isSuperAdmin && isViewOnly"
              />
              <v-text-field
                v-model="signup.occupantPhoneNumber"
                label="Phone Number"
                outlined
                dense
                :disabled="!user.isSuperAdmin && isViewOnly"
              />
              <v-text-field
                v-model="signup.occupantAlternatePhoneNumber"
                label="Alternate Phone Number"
                outlined
                dense
                :disabled="!user.isSuperAdmin && isViewOnly"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="signup.address"
                label="Address"
                outlined
                dense
                :disabled="!user.isSuperAdmin"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="signup.city"
                label="City"
                outlined
                dense
                :disabled="!user.isSuperAdmin"
              />
            </v-col>
            <v-col
              cols="4"
            >
              <v-select
                v-model="signup.province"
                :items="provinces"
                label="Province"
                outlined
                dense
                :disabled="!user.isSuperAdmin"
              />
            </v-col>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="signup.postalCode"
                label="Postal Code"
                outlined
                dense
                :disabled="!user.isSuperAdmin"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="signup.groundDisruptionId"
                label="Type of service drop required:"
              >
                <v-radio
                  v-for="item in groundDisruptionOptions"
                  :key="item.id"
                  :label="item.text"
                  :value="item.id"
                />
              </v-radio-group>
              <v-text-field
                v-if="isOtherDisruptionSelected"
                v-model="signup.otherGroundDisruptionText"
                dense
                outlined
                label="Other service drop"
              />
            </v-col>
            <v-col
              v-if="signup.planId"
              cols="2"
            >
              <div>
                <v-checkbox
                  v-for="item in questions"
                  :key="item.id"
                  v-model="checkedQuestions"
                  :label="item.text"
                  :value="item.id"
                  :disabled="!user.isSuperAdmin"
                />
              </div>
            </v-col>
            <v-col>
              <CallLog
                :calls="calls"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3>Property Sketch</h3>
              <a
                @click="openPropertySketchUrl"
              >
                <v-img
                  v-if="isImage"
                  :src="propertySketchUrl"
                  :max-height="500"
                  contain
                />
                <v-card
                  v-if="isPDF"
                  max-width="350"
                  class="my-10"
                >
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-img
                      class="ma-3"
                      src="../assets/pdf_icon.svg"
                      max-width="50"
                      contain
                    />
                    <v-card-title class="text-h5">
                      Open PDF
                    </v-card-title>
                  </div>
                </v-card>
              </a>
              <v-file-input
                v-if="!isViewOnly"
                v-model="signup.propertySketch"
                label="Property Sketch"
                outlined
                dense
                chips
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="signup.comment"
                dense
                outlined
                label="Comments"
                :disabled="!user.isSuperAdmin && isViewOnly"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3>Owner Signature</h3>
              <v-img
                :src="signatureUrl"
                :min-height="200"
                :min-width="400"
                :height="200"
                :width="400"
                contain
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="signup.createdByName"
                :disabled="true"
                dense
                outlined
                label="Created By"
              />
            </v-col>
            <v-col>
              <v-text-field
                :value="getFormattedDate(signup.createdAt)"
                :disabled="true"
                dense
                outlined
                label="Created At"
              />
            </v-col>
            <v-col>
              <v-text-field
                :value="signup.updatedAt ? getFormattedDate(signup.updatedAt) : ''"
                :disabled="true"
                dense
                outlined
                label="Last Updated At"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-checkbox
              v-model="signup.isApproved"
              label="Is Approved"
              class="pt-0 mt-0 mx-3"
              outlined
              dense
              :disabled="!user.isSuperAdmin && !user.isManager"
            />
          </v-row>
        </v-card-text>
        <v-card-actions
          v-if="user.isSuperAdmin || !isViewOnly"
        >
          <v-btn
            color="primary"
            :loading="loading"
            @click.native="submitSignup"
          >
            Submit Signup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import userMixin from '@/components/mixins/userMixin';
import { formatShortFullDate } from '@/shared/helpers/dayJsHelper';
import {
  getSignup, updateSignup, getGroundDisruptions, getQuestions,
} from '@/shared/apis/signupApi';
import { getCallsBySignupId } from '@/shared/apis/callApi';
import { success, error } from '@/shared/helpers/notificationHelper';

import CallLog from '@/components/CallLog.vue';

export default {
  components: {
    CallLog,
  },
  mixins: [userMixin],
  props: {
    signupId: {
      type: Number,
      required: true,
    },
  },
  pageTitle: 'Edit Signup',
  data() {
    return {
      loading: false,
      signup: {
        ownerName: '',
        ownerPhoneNumber: '',
        ownerAlternatePhoneNumber: '',
        occupantName: '',
        occupantPhoneNumber: '',
        occupantAlternatePhoneNumber: '',
        address: '',
        city: '',
        province: '',
        postalCode: '',
        propertySketchUrl: '',
        propertySketchContentType: '',
        selectedGroundDisruption: null,
        otherGroundDisruptionText: '',
        comment: '',
      },
      calls: [],
      isOwnerOccupied: false,
      groundDisruptionOptions: [
        {
          text: 'Place buried drop with Conduit',
          value: 1,
        },
        {
          text: 'Transitional - Aerial and Buried drop with Conduit',
          value: 2,
        },
        {
          text: 'Existing Conduit Repair and Install',
          value: 3,
        },
        {
          text: 'Other',
          value: 4,
        },
      ],
      questions: [],
      checkedQuestions: [],
      provinces: ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'],
    };
  },
  computed: {
    isViewOnly() {
      if (this.user.isSuperAdmin) return false;
      if (this.user.isManager) return false;
      return this.userId !== this.signup.createdById;
    },
    userId() {
      return this.$store.state.user.user.id;
    },
    propertySketchUrl() {
      const baseUrl = process.env.VUE_APP_API_BASE_URL;
      const relativeUrl = this.signup.propertySketchUrl;

      if (!relativeUrl) return '';

      const url = `${baseUrl}${relativeUrl}`;
      return url;
    },
    isImage() {
      if (!this.signup.propertySketchContentType) return false;

      return this.signup.propertySketchContentType.includes('image');
    },
    isPDF() {
      if (!this.signup.propertySketchContentType) return false;

      return this.signup.propertySketchContentType.includes('pdf');
    },
    signatureUrl() {
      const baseUrl = process.env.VUE_APP_API_BASE_URL;
      const relativeUrl = this.signup.signatureUrl;

      if (!relativeUrl) return '';

      const url = `${baseUrl}${relativeUrl}`;
      return url;
    },
    isOtherDisruptionSelected() {
      if (!this.signup.groundDisruptionId) return false;
      const selectedGroundDisruption = this.groundDisruptionOptions
        .find((item) => item.id === this.signup.groundDisruptionId);
      if (!selectedGroundDisruption) return false;
      return selectedGroundDisruption.text === 'Other';
    },
  },
  watch: {
    isOwnerOccupied(newVal) {
      if (newVal) {
        // Populate occupant text boxes with owner data
        this.signup.occupantName = this.signup.ownerName;
        this.signup.occupantPhoneNumber = this.signup.ownerPhoneNumber;
        this.signup.occupantAlternatePhoneNumber = this.signup.ownerAlternatePhoneNumber;
      } else {
        // Clear occupant text boxes
        this.signup.occupantName = '';
        this.signup.occupantPhoneNumber = '';
        this.signup.occupantAlternatePhoneNumber = '';
      }
    },
  },
  async created() {
    await this.getGroundDisruptions();
    await this.getQuestions();
    await this.loadSignup();
    await this.getCalls();
  },
  methods: {
    async submitSignup() {
      const formData = new FormData();
      formData.append('customer[name]', this.signup.ownerName);
      formData.append('customer[email]', this.signup.ownerEmail);
      formData.append('customer[phone_number]', this.signup.ownerPhoneNumber);
      formData.append('customer[alternate_phone_number]', this.signup.ownerAlternatePhoneNumber);
      formData.append('customer[address]', this.signup.address);
      formData.append('customer[city]', this.signup.city);
      formData.append('customer[province]', this.signup.province);
      formData.append('customer[postal_code]', this.signup.postalCode);

      if (this.signup.planId && this.signup.planId > 0) {
        formData.append('sale[plan_id]', this.signup.planId);
        formData.append('sale[sale_questions]', this.checkedQuestions);
      }

      formData.append('signup[occupant_name]', this.signup.occupantName);
      formData.append('signup[occupant_phone_number]', this.signup.occupantPhoneNumber);
      formData.append('signup[occupant_alternate_phone_number]', this.signup.occupantAlternatePhoneNumber);
      formData.append('signup[ground_disruption_id]', this.signup.groundDisruptionId);
      formData.append('signup[other_ground_disruption_text]', this.signup.otherGroundDisruptionText);
      formData.append('signup[comment]', this.signup.comment);
      formData.append('signup[is_referred_to_sales]', this.signup.isReferredToSales);
      formData.append('signup[is_approved]', this.signup.isApproved);
      formData.append('signup[approved_by_id]', this.userId);

      // if (!this.signup.signatureUrl) {
      //   const { canvas } = this.$refs.signatureCanvas.$refs;
      //   const dataUrl = canvas.toDataURL();

      //   formData.append('signup[signature]', dataUrl);
      // }

      if (this.signup.propertySketch) {
        formData.append('signup[property_sketch]', this.signup.propertySketch);
      }

      try {
        const result = await updateSignup(this.signupId, formData);
        success('Signup Updated.');
        this.signup = result;
      } catch (e) {
        error(e.message);
      }
    },
    async getGroundDisruptions() {
      this.groundDisruptionOptions = await getGroundDisruptions();
    },
    async getQuestions() {
      this.questions = await getQuestions();
    },
    async loadSignup() {
      this.signup = await getSignup(this.signupId);
      this.checkedQuestions = this.signup.saleQuestions.map((x) => (x.question_id));
    },
    async getCalls() {
      this.calls = await getCallsBySignupId(this.signupId);
    },
    setIsOwnerOccupied() {
      if (!this.signup) return;

      const isSameName = this.signup.ownerName === this.signup.occupantName;
      const isSamePhoneNumber = this.signup.ownerPhoneNumber === this.signup.occupantPhoneNumber;

      this.isOwnerOccupied = isSameName && isSamePhoneNumber;
    },
    openPropertySketchUrl() {
      window.open(this.propertySketchUrl, '_blank');
    },
    getFormattedDate(date) {
      return formatShortFullDate(date);
    },
  },
};
</script>

  <style lang="scss" scoped>

  </style>
